import React from 'react';
import { catchError, EMPTY, merge } from 'rxjs';
import { registerError } from '../errors';
import { loadImageCancellable } from '../utils';

export function usePrefetchImages(opts: { once: boolean; images: string[] }) {
	const onceRef = React.useRef<string[]>([]);
	if (onceRef.current.length === 0 && opts.images.length > 0) {
		onceRef.current = opts.images;
	}

	// this is going to be initialized just once
	const prefetchImages = opts.once ? onceRef.current : opts.images;

	React.useEffect(() => {
		if (prefetchImages.length === 0) {
			return;
		}

		const unique = [...new Set(prefetchImages)];

		// prefetch all images in parallel
		const prefetch = merge(
			...unique.map((image) =>
				loadImageCancellable(image, 'anonymous').pipe(
					// ignore errors so prefetching for other images continues
					catchError((err) => {
						registerError(err);
						return EMPTY;
					}),
				),
			),
		);

		// start prefetching
		const prefetching = prefetch.subscribe({
			error: (err) => {
				console.error(err);
			},
		});

		return () => {
			// cancel prefetching when not necessary anymore
			prefetching.unsubscribe();
		};
	}, [prefetchImages]);
}
