import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from './store';
import { initCommon } from '@common';
import { isDevBuild } from '@common/environment';
import { App } from './app';

initCommon();

const render = (Component: React.ComponentType) => {
	return ReactDOM.render(
		<AppProvider>
			<Component />
		</AppProvider>,
		document.getElementById('root'),
	);
};

render(App);

declare global {
	interface NodeModule {
		hot?: {
			accept: (path: string, cb: () => void) => void;
		};
	}
}

if (isDevBuild()) {
	if (module.hot) {
		module.hot.accept('./app', () => {
			const NextApp = require('./app').default;
			render(NextApp);
		});
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
